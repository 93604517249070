import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { FleetProvider } from './contexts/FleetContext';
import { UserProvider } from './contexts/UserContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { createTheme, ThemeProvider } from '@mui/material/styles';  // Import ThemeProvider and createTheme from Material-UI

// Replace with your Stripe public key
const stripePromise = loadStripe('pk_test_51NzlHXLQ4fRoePRFEzqwskbbummMrnQGOqPgdxbRQdsU5g4OUBCQBXfqw5gSuszFpMrRhrdT7dCGBbr3zcdFqO2500yEQQg56B');

// Create a theme object using Material-UI's createTheme
const theme = createTheme();

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>  {/* Wrap your app in ThemeProvider */}
      <UserProvider>
        <FleetProvider>
          <Elements stripe={stripePromise}>
            <App />
          </Elements>
        </FleetProvider>
      </UserProvider>
    </ThemeProvider>
  </React.StrictMode>
);
