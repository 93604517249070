import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [operator, setOperator] = useState(null); // Ensure this is defined

  return (
    <UserContext.Provider value={{ phone, setPhone, countryCode, setCountryCode, operator, setOperator }}>
      {children}
    </UserContext.Provider>
  );
};
