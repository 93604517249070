const ROAMR_API_URL = process.env.REACT_APP_ROAMR_API_URL;
const API_HOST_V1 = `${ROAMR_API_URL}/v1`;
const API_HOST_V2 = `${ROAMR_API_URL}/v2`;
const API_HOST_V3 = `${ROAMR_API_URL}/v3`;

export const getOperatorData = async (operatorId) => {
  const url = `${API_HOST_V1}/web-startup?` + new URLSearchParams({
    operator: operatorId,
  });
  return fetch(url, {
    headers: {
      'Content-Type': 'application/JSON',
    },
  })
  .then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
};

export const requestCode = async (phone, countryCode) => {
  const url = `${API_HOST_V1}/requestCode`; 
  const body = {
    phone,
    body: countryCode,
  };
  
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/JSON',
    },
  })
  .then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Failed to send SMS code. Please try again.');
    }
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
};

export const createCheckoutSession = async (params) => {
  const url = `${API_HOST_V3}/reservation-web/create-checkout-session`;
  const body = {
    ...params,
    amount: params.reservation_amount 
  };
  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/JSON',
    },
  })
  .then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Failed to create checkout session.');
    }
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
};

export const checkVehicleAvailable = async (vehicle_type, fleet_id, start_date, end_date) => {
  const url = `${API_HOST_V3}/reservation-web/check-vehicle-available?` + new URLSearchParams({
    vehicle_type,
    fleet_id,
    start_date,
    end_date
  });

  return fetch(url, {
    method: 'GET',
  }).then((response) => {
    if (response.ok) {
      return true;
    } else if (response.status === 404) {
      return response.json().then(errorData => {
        throw new Error(errorData.message || 'Vehicles unavailable');
      });
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const signUp = async (phone, code, operatorId) => {
  const url = `${API_HOST_V1}/signup`;
  const body = {
    password: 'password', 
    phone,
    code,
    operator_id: operatorId,
    fromWebApp: true
  };

  return fetch(url, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'Content-Type': 'application/JSON',
    },
  })
  .then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('Failed to sign in. Please try again.');
    }
  })
  .catch((error) => {
    console.error(error);
    throw error;
  });
};

export const getFleets = (operatorId) => {
  const url = `${API_HOST_V2}/reservation-web/fleets?`+ new URLSearchParams({
    operator_id: operatorId,
  });
  return fetch(url, {
    method: 'GET',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};


export const getPrice = (fleet_id, start_date, end_date, vehicle_type) => {
  const url = `${API_HOST_V3}/reservation-web/reservation-price?`+ new URLSearchParams({
    start_date,
    end_date,
    fleet_id, 
    vehicle_type,
  });
  return fetch(url, {
    method: 'GET',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const getFleet = async (fleetId) => {
  const url = `${API_HOST_V2}/reservation-web/fleet?`+ new URLSearchParams({
    fleet_id: fleetId,
  });
  return fetch(url, {
    method: 'GET',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  })
};


export const getFleetVehicles = (fleetId) => {
  const url = `${API_HOST_V2}/reservation-web/vehicles?`;
  return fetch(url, {
    method: 'GET',
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};

export const getCoupon = (fleetId, code) => {
  const url = `${API_HOST_V3}/reservation-web/coupon?`+ new URLSearchParams({
    fleet_id: fleetId,
    code
  });
  return fetch(url, {
    method: 'GET',
  }).then((response) => {
    console.log("response:", response)
    if (response.ok) {
      return response;
    } else {
      throw new Error('There was a problem with your request. Please try again later');
    }
  });
};


