import vehicleImage from '../images/ecosmart.png';
import vehicleImage_8 from '../images/vehicletype-8.png';
import vehicleImage_17 from '../images/vehicletype-17.png';
import vehicleImage_18 from '../images/vehicletype-18.png';
import vehicleImage_20 from '../images/vehicletype-20.png';
import vehicleImage_21 from '../images/vehicletype-21.png';
import vehicleImage_22 from '../images/vehicletype-22.png';


export const getVehicleImage = (vehicleType) => {
    switch (vehicleType) {
      case 8:
        return vehicleImage_8;
      case 17:
        return vehicleImage_17;
      case 18:
        return vehicleImage_18;
      case 20:
        return vehicleImage_20;
      case 21:
        return vehicleImage_21;
      case 22:
        return vehicleImage_22;
      default:
        return vehicleImage;
    }
  };