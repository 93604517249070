import React, { createContext, useState } from 'react';

const FleetContext = createContext();

const FleetProvider = ({ children }) => {
  const [fleet_id, setFleetId] = useState(null); 
  const [fleet_name, setFleetName] = useState(null); 
  const [menu_bkg_color, setMenuBkgColor] = useState('#2e3951'); 
  const [web_bkg_color, setWebBkgColor] = useState('#2e3951'); 
  const [web_logo, setWebLogo] = useState('https://evank.com/roamr_test/operator-logo-roamr.png'); 

  const setFleetData = (data) => {
    setFleetId(data.fleet_id || '');
    setFleetName(data.fleet_name || '');
    setMenuBkgColor(data.menu_bkg_color || '#FFFFFF'); 
    setWebBkgColor(data.web_bkg_color || '#000000'); 
    setWebLogo(data.web_logo || 'default-logo.png');
  };

  return (
    <FleetContext.Provider value={{ menu_bkg_color, web_bkg_color, web_logo, fleet_id, fleet_name, setFleetData }}>
      {children}
    </FleetContext.Provider>
  );
};

export { FleetContext, FleetProvider };
